import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { UX2 } from '@wsb/guac-widget-core';
import { scrollOlaWidget } from '../../../common/actions/ScrollWidgetActions';
import dataAids from '../../../common/constants/dataAids';
import CategoryLink from './CategoryLink';

const LinkList = ({ categories, selectedCategoryId, staticContent, onCategoryClick }) => {
  const {
    Element: { List, ListItem },
    Group: { Nav }
  } = UX2;
  const styles = {
    list: {
      '@xs-only': {
        display: 'table',
        width: '100%',
        marginBottom: 'small'
      },
      '@sm': {
        marginBottom: 'small'
      }
    },
    category: {
      position: 'relative',
      '@xs-only': {
        display: 'block',
        marginTop: 'medium',
        marginLeft: '0'
      },
      '@sm': {
        paddingBottom: 'medium'
      }
    }
  };

  return (
    <Nav.Horizontal style={ styles.list }>
      <List>
        { categories.map(category => {
          return (
            <ListItem.Inline key={ category.id } style={ styles.category }>
              <CategoryLink
                content={ category.permanent ? staticContent.allServices : category.name }
                identifier={ category.permanent ? 'ALL_SERVICES' : category.id }
                isSelected={ selectedCategoryId === category.id }
                onLinkClick={ e => onCategoryClick(category.id, e) }
              />
            </ListItem.Inline>
          );
        }) }
      </List>
    </Nav.Horizontal>
  );
};

LinkList.propTypes = {
  categories: PropTypes.array,
  staticContent: PropTypes.object,
  selectedCategoryId: PropTypes.number,
  onCategoryClick: PropTypes.func
};

class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.toggleMobileList = this.toggleMobileList.bind(this);
    this.onCategoryClick = this.onCategoryClick.bind(this);
    this.scrollMobileToTop = this.scrollMobileToTop.bind(this);
    this.state = { isMobileListVisible: false };
  }

  scrollMobileToTop() {
    // eslint-disable-next-line react/no-find-dom-node
    const mobileInnerNode = findDOMNode(this.mobileInnerRef);

    if (!mobileInnerNode) return;

    mobileInnerNode.scrollTop = 0;
  }

  toggleMobileList() {
    const { isMobileListVisible } = this.state;
    this.scrollMobileToTop();
    this.setState({ isMobileListVisible: !isMobileListVisible });
  }

  onCategoryClick(id, e) {
    e.preventDefault();

    this.props.onCategoryClick(id);
    scrollOlaWidget();
    this.setState({ isMobileListVisible: false });
    this.scrollMobileToTop();
  }

  render() {
    const { categories, staticContent } = this.props;

    if (!categories.length) return null;

    const { Block, Link, HR, Icon } = UX2.Element;
    const { isMobileListVisible } = this.state;
    const { selectedCategoryId } = this.props;

    const selectedCategory = categories.find(c => c.id === selectedCategoryId) || categories[0];

    const styles = {
      desktop: {
        '@xs-only': {
          display: 'none'
        }
      },
      mobile: {
        position: 'relative',
        flexDirection: 'column',
        marginBottom: 'medium',
        '@sm': {
          display: 'none'
        },
        toggleOuter: {
          margin: '0',
          fontColor: 'highContrast'
        },
        toggleInner: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginVertical: 'xsmall',
          fontColor: 'highlight'
        },
        toggleName: {
          marginRight: 'xsmall',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          fontColor: '!inherit',
          fontWeight: 'bold'
        },
        toggleIcon: {
          flexShrink: '0'
        },
        listOuter: {
          transition: 'max-height 0.5s',
          position: 'relative',
          width: '100%',
          maxHeight: isMobileListVisible ? '50vh' : '0vh',
          overflow: 'auto'
        },
        hr: {
          marginVertical: '0'
        }
      }
    };

    const listProps = {
      categories,
      selectedCategoryId,
      staticContent,
      onCategoryClick: this.onCategoryClick
    };

    return (
      <Block>
        <Block style={ styles.desktop }>
          { categories && categories.length && (
            <LinkList data-aid={ dataAids.CATEGORY_LIST_CONTAINER } { ...listProps } />
          ) }
        </Block>
        <Block style={ styles.mobile }>
          <Link
            data-aid={ dataAids.CATEGORY_LIST_TOGGLE }
            onClick={ this.toggleMobileList }
            style={ styles.mobile.toggleOuter }
          >
            <HR style={ styles.mobile.hr } />
            <Block style={ styles.mobile.toggleInner }>
              <Block style={ styles.mobile.toggleName }>{ selectedCategory.name }</Block>
              <Icon
                icon='chevronDown'
                size='medium'
                rotate={ isMobileListVisible ? '-180' : '0' }
                style={ styles.mobile.toggleIcon }
              />
            </Block>
            <HR style={ styles.mobile.hr } />
          </Link>
          <Block ref={ ref => (this.mobileInnerRef = ref) } style={ styles.mobile.listOuter }>
            { categories && categories.length && (
              <LinkList
                { ...listProps }
                isMobile={ true }
                data-aid={ dataAids.CATEGORY_LIST_CONTAINER }
              />
            ) }
            <HR style={ styles.mobile.hr } />
          </Block>
        </Block>
      </Block>
    );
  }
}

CategoryList.propTypes = {
  categories: PropTypes.array,
  selectedCategoryId: PropTypes.number,
  staticContent: PropTypes.object.isRequired,
  onCategoryClick: PropTypes.func
};

export default CategoryList;
