import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { UX2 } from '@wsb/guac-widget-core';
import dataAids from '../../../common/constants/dataAids';

class CategoryLink extends Component {
  render() {
    const { content, identifier, isSelected, onLinkClick } = this.props;

    const {
      Link,
      Link: { Active }
    } = UX2.Element;

    const styles = {
      link: {
        pointerEvents: isSelected ? 'none' : 'auto',
        '@xs-only': {
          whiteSpace: 'normal'
        },
        '@sm': {
          whiteSpace: 'nowrap',
          cursor: isSelected ? 'auto' : 'pointer'
        }
      }
    };

    const LinkComponent = isSelected ? Active : Link;

    return (
      <LinkComponent
        data-aid={ `${dataAids.CATEGORY_LINK}_${identifier}` }
        onClick={ onLinkClick }
        style={ styles.link }
        tag='span'
      >
        { content }
      </LinkComponent>
    );
  }
}

CategoryLink.propTypes = {
  content: PropTypes.string,
  identifier: PropTypes.number,
  isSelected: PropTypes.bool,
  isMobile: PropTypes.bool,
  onLinkClick: PropTypes.func
};

CategoryLink.defaultProps = {
  isMobile: false
};

export default CategoryLink;
