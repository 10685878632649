import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { UX2 } from '@wsb/guac-widget-core';
import ServiceListStore from '../../common/stores/ServiceList';
import CategoryListStore from '../../common/stores/CategoryList';
import ConfigStore from '../../common/stores/Config';
import FormattersStore from '../../common/stores/Formatters';
import {
  fetchServices,
  fetchCategoryServices,
  showAllServices,
  updateServiceType
} from '../../common/actions/ServiceList';
import Field from '../../common/constants/editable-field-tags';
import { fetchCategories, selectCategory } from '../../common/actions/CategoryList';
import { Fetching } from '../../common/components/Fetching';
import dataAids from '../../common/constants/dataAids';
import renderModes from '../../common/constants/renderModes';
import CategoryList from './categories/CategoryList';
import Duration from '../../common/components/Duration';
import CLSHandler from '../../common/components/CLSHandler';
import { Translate as translate } from '@wsb/vnext-widget-content-translate';
import { isAppointment, isEvent, isSingleEvent } from '../../common/utils/recurrenceType';
import { onServiceClick } from '../../common/utils/onServiceClick';
import { trackMetric, trackC1 } from '../../common/utils/trackMetric';
import serviceRecurrenceTypes from '../../common/constants/recurrenceTypes';
import { getCostText, getCostDataAid } from '../../common/utils/getCostInfo';
import { costTypes } from '../../common/constants/costTypes';
import {
  LAYOUT_1_IMPRESSION,
  SERVICE_LIST_APPOINTMENTS_TAB_IMPRESSION,
  SERVICE_LIST_APPOINTMENTS_TAB_CLICK,
  SERVICE_LIST_EVENTS_TAB_IMPRESSION,
  SERVICE_LIST_EVENTS_TAB_CLICK,
  SERVICES_DISPLAYED_IMPRESSION,
  C1_SERVICES_DISPLAYED_IMPRESSION,
  C1_SERVICES_NOT_DISPLAYED_IMPRESSION,
  OLA_WIDGET_ZERO_STATE_IMAGES_CONTROL_IMPRESSION,
  OLA_WIDGET_ZERO_STATE_IMAGES_VARIANT_IMPRESSION
} from '../../common/constants/metrics';
import TrackImpression from '../../common/components/TrackImpression';

const tabStyles = {
  display: 'block',
  paddingHorizontal: 'xsmall',
  paddingVertical: 'xsmall',
  borderWidth: '1px',
  borderColor: 'highlight',
  borderStyle: 'solid',
  font: 'alt',
  textAlign: 'center',
  cursor: 'pointer',
  '@xs-only': {
    maxWidth: '50%',
    flexBasis: '50%'
  },
  '@sm': {
    minWidth: '150px'
  }
};

export default class ServiceList extends Component {
  constructor(props) {
    super(props);
    this.onStoreChange = this.onStoreChange.bind(this);
    this.onCategoryClick = this.onCategoryClick.bind(this);
    this.state = this.getStoreState();
  }

  getStoreState() {
    const serviceListState = ServiceListStore.getState();
    const categoryListState = CategoryListStore.getState();
    const {
      timeZone,
      websiteId,
      olaFbPixelId,
      initial_service_card_count: initialServiceCardCount
    } = ConfigStore.getState();

    const state = {
      ...serviceListState,
      ...categoryListState
    };

    const isFetching = serviceListState.isFetching || categoryListState.isFetching;

    const error = serviceListState.error || categoryListState.error;

    return {
      ...state,
      isFetching: !error && isFetching,
      error,
      timeZone,
      initialServiceCardCount,
      websiteId,
      olaFbPixelId
    };
  }

  componentDidMount() {
    ServiceListStore.addListener('change', this.onStoreChange);
    CategoryListStore.addListener('change', this.onStoreChange);
    ConfigStore.addListener('change', this.onStoreChange);

    const { areServicesHidden } = this.props;

    if (areServicesHidden) {
      trackC1(C1_SERVICES_NOT_DISPLAYED_IMPRESSION);
      this.trackZeroStateImagesImpression();
      return;
    }
    trackMetric(LAYOUT_1_IMPRESSION);

    setTimeout(() => {
      fetchCategories(categories => {
        if (categories.length) {
          // If categories exist, default the selected category to the first category.
          const firstCategoryId = categories[0].id;

          selectCategory(firstCategoryId);

          if (ConfigStore.isGopayCartOn()) {
            fetchServices(firstCategoryId).then(isSuccess => {
              if (!isSuccess) return;
              const { services } = this.state;
              this.trackServicesImpression(services);
              if (!services.length) {
                this.trackZeroStateImagesImpression();
              }
            });
          } else {
            fetchCategoryServices(firstCategoryId);
          }
        } else {
          fetchServices().then(isSuccess => {
            if (!isSuccess) return;
            const { services } = this.state;
            this.trackServicesImpression(services);
            if (!services.length) {
              this.trackZeroStateImagesImpression();
            }
          });
        }
      });
    }, 0);
  }

  componentWillUnmount() {
    ServiceListStore.removeListener('change', this.onStoreChange);
    CategoryListStore.removeListener('change', this.onStoreChange);
    ConfigStore.removeListener('change', this.onStoreChange);
  }

  componentDidUpdate() {
    if (!this.hasLoggedListImpression && this.state.services.length) {
      trackMetric(SERVICES_DISPLAYED_IMPRESSION);
      this.hasLoggedListImpression = true;
    }
  }

  trackServicesImpression(services) {
    if (!services.length) {
      trackC1(C1_SERVICES_NOT_DISPLAYED_IMPRESSION);
    } else {
      trackC1(C1_SERVICES_DISPLAYED_IMPRESSION);
    }
  }

  trackZeroStateImagesImpression() {
    const { showStockImagesZeroState } = this.props;

    if (showStockImagesZeroState) {
      trackC1(OLA_WIDGET_ZERO_STATE_IMAGES_VARIANT_IMPRESSION);
    } else {
      trackC1(OLA_WIDGET_ZERO_STATE_IMAGES_CONTROL_IMPRESSION);
    }
  }

  onStoreChange() {
    this.setState(this.getStoreState());
  }

  onCategoryClick(categoryId) {
    selectCategory(categoryId);

    if (ConfigStore.isGopayCartOn()) {
      fetchServices(categoryId);
    } else {
      fetchCategoryServices(categoryId);
    }
  }

  areServicesShown() {
    const { isSampleDataUsed, areServicesHidden } = this.props;

    if (isSampleDataUsed) {
      return true;
    }

    return Boolean(!areServicesHidden && this.getFilteredServices().length);
  }

  getSampleServices() {
    const { staticContent, stockImages, showStockImagesZeroState } = this.props;
    const interpolate = translate(staticContent);

    return [
      {
        image_url: showStockImagesZeroState ? stockImages[0] : '',
        name: interpolate('editorSampleServices.first.name'),
        cost_type: costTypes.FREE,
        cost: '0',
        description: interpolate('editorSampleServices.first.description'),
        duration: 'PT15M',
        id: 1
      },
      {
        image_url: showStockImagesZeroState ? stockImages[1] : '',
        name: interpolate('editorSampleServices.second.name'),
        cost_type: costTypes.COST,
        cost: '10.0',
        description: interpolate('editorSampleServices.second.description'),
        duration: 'PT1H',
        id: 2
      },
      {
        image_url: showStockImagesZeroState ? stockImages[2] : '',
        name: interpolate('editorSampleServices.third.name'),
        cost_type: costTypes.CUSTOM_TEXT,
        custom_cost_text: interpolate('editorSampleServices.third.customCostText'),
        description: interpolate('editorSampleServices.third.description'),
        duration: 'PT30M',
        id: 3
      }
    ];
  }

  getAppointmentServices() {
    const { services } = this.state;

    return services.filter(service => isAppointment(service.recurrence_type));
  }

  getEventServices() {
    const { services } = this.state;

    return services.filter(service => isEvent(service.recurrence_type));
  }

  doSingleEventServicesExist() {
    const { services } = this.state;

    return services.some(service => isSingleEvent(service.recurrence_type));
  }

  getFilteredServices() {
    const { isSampleDataUsed } = this.props;

    if (isSampleDataUsed) {
      return this.getSampleServices();
    }

    const { serviceType } = this.state;
    const appointmentServices = this.getAppointmentServices();
    const eventServices = this.getEventServices();

    return (serviceType === 'appointments' && Boolean(appointmentServices.length)) ||
      (serviceType === 'events' && !eventServices.length)
      ? appointmentServices
      : eventServices;
  }

  getDisplayedServices() {
    const filteredServices = this.getFilteredServices();
    if (!this.isSeeAllLinkShown()) return filteredServices;
    const { initialServiceCardCount } = this.state;
    return filteredServices.slice(0, initialServiceCardCount);
  }

  isSeeAllLinkShown() {
    const { areAllServicesShown, initialServiceCardCount } = this.state;

    return (
      this.areServicesShown() &&
      !areAllServicesShown &&
      this.getFilteredServices().length > initialServiceCardCount
    );
  }

  areServicesTypeTabsShown() {
    const { isGetStartedCtaDisplayed } = this.props;
    return (
      this.areServicesShown() &&
      Boolean(this.getAppointmentServices().length) &&
      Boolean(this.getEventServices().length) &&
      !isGetStartedCtaDisplayed
    );
  }

  isErrorShown() {
    const { error } = this.state;
    const { renderMode, isGetStartedCtaDisplayed } = this.props;

    if (!error) return false;
    if (isGetStartedCtaDisplayed) return false;
    if ([renderModes.ADD, renderModes.DISPLAY, renderModes.SAMPLE].includes(renderMode))
      return false;
    return true;
  }

  isTimeZoneDisplayed() {
    const { isGetStartedCtaDisplayed } = this.props;

    if (!this.doSingleEventServicesExist()) return false;
    if (isGetStartedCtaDisplayed) return false;
    return true;
  }

  onClickAppointmentsTab = () => {
    updateServiceType('appointments');
    trackMetric(SERVICE_LIST_APPOINTMENTS_TAB_CLICK);
  };

  onClickEventsTab = () => {
    updateServiceType('events');
    trackMetric(SERVICE_LIST_EVENTS_TAB_CLICK);
  };

  isServicesSectionShown = ({ isServicesCommingMessageShown }) => {
    return this.areServicesShown() || isServicesCommingMessageShown;
  };

  /* eslint-disable complexity */
  render() {
    const { isFetching, categories, selectedCategoryId, serviceType, areServicesLoaded } =
      this.state;

    const {
      staticContent,
      callToActionText,
      imageCropMethod,
      isGetStartedCtaDisplayed,
      areServicesHidden,
      navigate,
      renderMode,
      section
    } = this.props;

    const services = this.getDisplayedServices();

    const isCategoryListShown =
      !areServicesHidden && categories.length > 1 && !isGetStartedCtaDisplayed;
    const isServicesCommingMessageShown =
      areServicesHidden || (areServicesLoaded && !services.length);
    const { dateTimeFormatter } = FormattersStore.getState();
    const buttonText = callToActionText || staticContent.book;

    const {
      Element: { Block, Text, MoreLink },
      Component: { Grid, CommerceCardItem, PlaceholderMessage }
    } = UX2;

    const styles = {
      grid: {
        justifyContent: services.length < 3 ? 'center' : 'flex-start'
      },
      timeZone: {
        marginTop: 'xlarge',
        textAlign: 'center',
        '@xs-only': {
          marginTop: 'large'
        }
      },
      seeAll: {
        display: 'inline-block',
        marginTop: 'xlarge',
        cursor: 'pointer'
      },
      error: {
        width: '100%',
        marginBottom: 'medium',
        marginTop: 'xxlarge',
        textAlign: 'center',
        font: 'alt',
        fontColor: 'error'
      },
      tabContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 'large',
        '@xs-only': {
          marginBottom: 'medium'
        }
      },
      appointmentsTab: {
        ...tabStyles,
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        backgroundColor: serviceType === 'appointments' ? 'action' : 'transparent',
        fontColor: serviceType === 'appointments' ? 'action' : 'highlight'
      },
      eventsTab: {
        ...tabStyles,
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        backgroundColor: serviceType === 'events' ? 'action' : 'transparent',
        fontColor: serviceType === 'events' ? 'action' : 'highlight'
      }
    };

    return (
      <Block>
        { isCategoryListShown && (
          <CategoryList
            categories={ categories }
            staticContent={ staticContent }
            selectedCategoryId={ selectedCategoryId }
            onCategoryClick={ this.onCategoryClick }
          />
        ) }
        { this.areServicesTypeTabsShown() && (
          <Block style={ styles.tabContainer } data-aid={ dataAids.SERVICE_LIST_RECURRENCE_TYPE_TABS }>
            <TrackImpression metric={ SERVICE_LIST_APPOINTMENTS_TAB_IMPRESSION }>
              <Text onClick={ this.onClickAppointmentsTab } style={ styles.appointmentsTab }>
                { staticContent.appointments }
              </Text>
            </TrackImpression>
            <TrackImpression metric={ SERVICE_LIST_EVENTS_TAB_IMPRESSION }>
              <Text onClick={ this.onClickEventsTab } style={ styles.eventsTab }>
                { staticContent.classesOrEvents }
              </Text>
            </TrackImpression>
          </Block>
        ) }
        { this.isErrorShown() && (
          <Text style={ styles.error } data-aid={ dataAids.SERVICE_LIST_ERROR }>
            { staticContent.defaultError }
          </Text>
        ) }
        { this.isServicesSectionShown({ isServicesCommingMessageShown }) && (
          <CLSHandler>
            { this.areServicesShown() ? (
              <Grid size={ 6 } style={ styles.grid }>
                { services.map(service => {
                  const eventDate =
                    service.recurrence_type === serviceRecurrenceTypes.NONE
                      ? dateTimeFormatter.formatDateTime(service.start_time)
                      : null;

                  return (
                    <Grid.Cell key={ service.id } xs={ 6 } sm={ 3 } md={ 2 }>
                      <CommerceCardItem
                        name={ service.name }
                        duration={
                          <Duration duration={ service.duration } staticContent={ staticContent } />
                        }
                        eventDate={ eventDate }
                        price={ getCostText(service, staticContent) }
                        imageUrl={ service.image_url }
                        imageShape='horizontal'
                        imageCropMethod={ imageCropMethod }
                        isBoxed
                        buttonText={ buttonText }
                        dataAids={{
                          name: dataAids.SERVICE_LIST_ITEM_SERVICE_NAME,
                          price: getCostDataAid(service),
                          eventDate: dataAids.SERVICE_LIST_ITEM_SERVICE_EVENT_DATE,
                          button: dataAids.SERVICE_LIST_ITEM_BOOK_BUTTON,
                          image: dataAids.SERVICE_LIST_ITEM_ASSET
                        }}
                        dataRoutes={{ button: Field.CTA }}
                        onClick={ () => onServiceClick(service, navigate, renderMode) }
                      />
                    </Grid.Cell>
                  );
                }) }
              </Grid>
            ) : (
              isServicesCommingMessageShown && (
                <PlaceholderMessage
                  section={ section === 'default' ? 'alt' : 'default' }
                  message={ staticContent.servicesCommingSoon }
                  textProps={{
                    'data-aid': dataAids.SERVICE_LIST_NO_SERVICES,
                    style: { fontSize: 'large' }
                  }}
                />
              )
            ) }
          </CLSHandler>
        ) }
        { this.isTimeZoneDisplayed() && (
          <Text data-aid={ dataAids.SERVICE_LIST_TIME_ZONE } style={ styles.timeZone }>
            { FormattersStore.getState().dateTimeFormatter.getTimeZoneDisplayName() }
          </Text>
        ) }
        { this.isSeeAllLinkShown() && (
          <Block style={{ textAlign: 'center' }}>
            <MoreLink.Expand
              data-aid={ dataAids.SERVICE_LIST_SEE_ALL_LINK }
              onClick={ showAllServices }
              style={ styles.seeAll }
              tag='span'
            >
              { staticContent.seeAll }
            </MoreLink.Expand>
          </Block>
        ) }
        <Fetching fetching={ isFetching } />
      </Block>
    );
  }
  /* eslint-enable complexity */
}

ServiceList.propTypes = {
  renderMode: PropTypes.string,
  staticContent: PropTypes.object.isRequired,
  navigate: PropTypes.func,
  isSampleDataUsed: PropTypes.bool,
  callToActionText: PropTypes.string,
  imageCropMethod: PropTypes.string,
  viewDevice: PropTypes.string,
  isGetStartedCtaDisplayed: PropTypes.bool,
  areServicesHidden: PropTypes.bool.isRequired,
  olaFbPixelId: PropTypes.string,
  section: PropTypes.string
};
